<template>
  <tr class="" v-if="song">
    <td class="px-2 py-2 text-emerald-600 font-medium">
      <span :class="'inline-flex items-center justify-center px-2 py-1 mr-2 text-xs font-bold leading-none text-red-100 rounded-full ' + getBgClass(index)">{{ index }}</span>
    </td>
    <td class="space-x-4 px-2 py-2 text-emerald-600 font-medium flex items-center">
      <div class="w-14 h-14 flex-shrink-0 rounded-md relative">
        <router-link v-if="song.id && prefix !== 'thanh-vien-hat'" :title="song.title" class="text-lg font-semibold" :to="{name: 'SongDetail', params: {id: song.id, slug: song.slug, prefix: prefix}}">
          <img :alt="song.title" :src="song.thumbnail ? song.thumbnail.url : song.artists && song.artists.data && song.artists.data[0] && song.artists.data[0].avatar ? song.artists.data[0].avatar.url : 'https://static.bcdcnt.net/assets/images/group/group-3.jpg'" class="absolute w-full h-full inset-0 rounded-md object-cover">
        </router-link>
        <router-link v-if="song.id && prefix === 'thanh-vien-hat'" :title="song.title" class="text-lg font-semibold" :to="{name: 'SongDetail', params: {id: song.id, slug: song.slug, prefix: prefix}}">
          <img :alt="song.title" :src="song.thumbnail ? song.thumbnail.url : song.user && song.user.avatar ? song.user.avatar.url : 'https://static.bcdcnt.net/assets/images/group/group-3.jpg'" class="absolute w-full h-full inset-0 rounded-md object-cover">
        </router-link>
        <img v-if="!song.id" :alt="song.title" src="https://static.bcdcnt.net/assets/images/group/group-3.jpg" class="absolute w-full h-full inset-0 rounded-md object-cover">
      </div>
      <div class="flex-1">
        <router-link v-if="song.id" :title="song.title" class="text-lg font-semibold" :to="{name: 'SongDetail', params: {id: song.id, slug: song.slug, prefix: prefix}}">
          {{ song.title }}
          <span class="italic text-sm font-normal" v-if="song.subtitle">{{ song.subtitle }}</span>
        </router-link>
        <div v-if="!song.id" class="text-lg font-semibold">{{ song.title }} <span class="text-gray-500" v-if="song.subtitle">{{ song.subtitle }}</span></div>
        <div class="text-sm text-gray-500 mt-0.5 people-in-song line-clamp-1" v-if="song.artists && song.artists.data">
          <PeopleOfSong :people="song.artists.data" type="artist"/>
        </div>
        <div class="text-sm text-gray-500 mt-0.5 people-in-song line-clamp-1" v-if="song.users && song.users.data">
          <users-of-song :people="song.users.data"/>
        </div>
      </div>
    </td>
    <td v-if="showViews === true" class="px-2 py-2 text-emerald-600 font-medium whitespace-nowrap" :uk-tooltip="'title: ' + thousandFormat(total) + ' lượt nghe'">
      <i class="uil uil-headphones"></i> {{ numberFormat(total) }}
    </td>
  </tr>
</template>

<script>
import PeopleOfSong from "../song/PeopleOfSong";
import UsersOfSong from "../song/UserOfSong";
import { numberFormat, thousandFormat } from "../../core/services/utils.service";

export default {
  name: "StatisticItem",
  props: {
    prefix: String,
    total: Number,
    index: Number,
    showViews: Boolean,
    song: {
      type: Object,
      default() {
        return {
          title: ""
        };
      }
    }
  },
  components: {
    UsersOfSong,
    PeopleOfSong
  },
  methods: {
    numberFormat(x) {
      return numberFormat(x);
    },
    thousandFormat(x) {
      return thousandFormat(x);
    },
    getBgClass(index) {
      switch (index) {
        case 1:
          return 'bg-pink-800';
        case 2:
          return 'bg-pink-600';
        case 3:
          return 'bg-pink-400';
        default:
          return 'bg-pink-400';
      }
    }
  }
}
</script>
