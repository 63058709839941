<template>
  <tr class="">
    <td class="px-2 py-2 text-emerald-600 font-medium">
      <span :class="'inline-flex items-center justify-center px-2 py-1 mr-2 text-xs font-bold leading-none text-red-100 rounded-full ' + getBgClass(index)">{{ index }}</span>
    </td>
    <td class="px-2 py-2 text-emerald-600 font-medium flex items-center">
      <router-link :to="{name: 'UserDetail', params: {id: item.user_id}}" class="mr-1">
        <img
            :src="item.avatar ? item.avatar : 'https://static.bcdcnt.net/assets/images/avatars/avatar-4.jpg'"
            :alt="item.username" class="w-10 h-10 rounded-full object-cover mr-2">
      </router-link>
      <router-link :to="{name: 'UserDetail', params: {id: item.user_id}}">
        {{ item.username }}
      </router-link>
    </td>
    <td class="px-2 py-2 text-emerald-600 font-medium" :uk-tooltip="
                'title: ' + thousandFormat(item.total) + ' bản thu'
              ">
      <i class="fa fa-cloud-upload-alt"></i> {{ numberFormat(item.total) }}
    </td>
  </tr>
</template>

<script>
import {thousandFormat, numberFormat} from "../../core/services/utils.service";

export default {
  name: "TopUploadItem",
  props: {
    index: Number,
    item: {
      type: Object,
      default() {
        return {
          user_id: "",
          username: "",
          avatar: "",
          total: "",
        };
      }
    }
  },
  methods: {
    getBgClass(index) {
      switch (index) {
        case 1:
          return 'bg-pink-800';
        case 2:
          return 'bg-pink-600';
        case 3:
          return 'bg-pink-400';
        default:
          return 'bg-pink-400';
      }
    },
    thousandFormat(x) {
      return thousandFormat(x);
    },
    numberFormat(x) {
      return numberFormat(x);
    },
  }
}
</script>
